import React from 'react'
import Helmet from 'react-helmet'
import Contatti from '../components/contatti'
import Img from 'gatsby-image'
import Breadcrumbs from '../components/breadcrumbs'
import Curriculum from '../components/curriculum'

import Layout from "../components/layout"
import { graphql } from "gatsby"

class PersonaTemplate extends React.Component {

  render() { 
    const data = this.props.data.contentfulPersona;
    const thumb = data.immagine || data.immagineLista || this.props.data.placeholder.childImageSharp;

    const breadcrumb = [
        {"title":"Staff","link":"/staff/"},
        {"title":`${data.cognome} ${data.nome}`}];
    

    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title={data.nome +" "+ data.cognome}/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <article className="staff">
                <h1 className="staff__name">{data.nome} {data.cognome}</h1>
         
                <div className="row">
                  <div className="col-md-4 text-center">
                    <Img alt={data.nome + data.cognome} fluid={thumb.fluid} className="staff__img"/>
                  </div>
                  <div className="col-md-8">
                    {!!data.testo &&
                      <div className="staff__text" dangerouslySetInnerHTML={{ __html: data.testo.childMarkdownRemark.html }}>

                      </div>
                    }
                  </div>
                </div>
              </article>  
            </div>
            <div className="col-md-4">
              {!!data.curriculum &&
                <Curriculum data={data.curriculum}/>
              }
              <Contatti data={data}/>
             
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default PersonaTemplate;

export const pageQuery = graphql`query PersonaDetailQuery($contentful_id: String!) {
  contentfulPersona(contentful_id: {eq: $contentful_id}) {
    id
    nome
    cognome
    ruolo
    telefono
    email
    testo {
      childMarkdownRemark {
        html
      }
    }
    immagineLista {
      fluid(maxWidth: 380){
        ...GatsbyContentfulFluid_withWebp
      }
    }
    immagine {
      fluid(maxWidth: 380){
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
  placeholder: file(relativePath: {eq: "staff-placeholder.png"}) {
    childImageSharp{
      fluid(maxWidth: 255){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`;
